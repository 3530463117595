import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";
import { BlobProvider } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { ParamsGetDetailInvoice, TipeStruk } from "../../constants/types";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import toRupiah from "../../utils/toRupiah";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import PrintStrukPdf from "../PrintStruk/PrintStrukPdf";
import useFindCustomStruk from "../../services/queries/useFindCustomStruk";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PrintStrukA4 from "../PrintStrukA4/PrintStrukA4";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { Tooltip } from "@mui/material";
import truncate from "../../utils/truncate";
import useDetailInvoice from "../../services/queries/useDetailInvoice";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import toTitleCase from "../../utils/toTitleCase";
import moment from "moment";
import "moment/locale/id";

interface IModalDetailInvoiceProps {
    id: number;
    fullScreen: boolean;
    isOpenModalDetailInvoice: boolean;
    closeModalDetailInvoice: () => void;
}

const ModalDetailInvoice = ({
    id,
    fullScreen,
    isOpenModalDetailInvoice,
    closeModalDetailInvoice,
}: IModalDetailInvoiceProps) => {
    const { data: user } = useUser();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const { data: findCustomStruk } = useFindCustomStruk(Number(ukmId));
    const [kirimEmail, setKirimEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [tipeStruk, setTipeStruk] = useState<string | null>(
        localStorage.getItem("tipeStrukLaporan") ?? TipeStruk.Mobile,
    );

    const [isIncludeDapur, setIsIncludeDapur] = useState<boolean>(
        localStorage.getItem("isIncludeDapur") === "true"
    );

    const handleKirimEmail = (checked: boolean) => {
        if (checked) {
            setEmail("");
            setKirimEmail(true);
        } else {
            setKirimEmail(false);
        }
    };

    const handleTipeStruk = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStruk(newTipeStruk);
            localStorage.setItem("tipeStrukLaporan", newTipeStruk);

            const isDapur = newTipeStruk === TipeStruk.MobileDapur || newTipeStruk === TipeStruk.Mobile80Dapur;
            setIsIncludeDapur(isDapur);
            localStorage.setItem("isIncludeDapur", String(isDapur));
        }
    };

    const initialParamsDetailInvoice = {
        id: id,
        grupProduk: 0,
    };

    const [paramsDetailInvoice, setParamsDetailInvoice] =
        useState<ParamsGetDetailInvoice>(initialParamsDetailInvoice);

    const { data: detailInvoice, isLoading: isLoadingDetailInvoice } =
        useDetailInvoice(paramsDetailInvoice);

    const newCart = detailInvoice?.daftarItem.map((items, index) => ({
        id: index,
        namaBarang: String(items.namaItem),
        qty: Number(items.jumlahItem),
        harga: Number(items.hargaSatuan),
        namaKategori: String(items.namaKategori),
    }));

    useEffect(() => {
        if (id) {
            setParamsDetailInvoice((prev) => ({ ...prev, id: id }));
        }
    }, [id]);

    const groupByKategori = false;

    if (isLoadingDetailInvoice) {
        return <LoadingOverlay open />;
    }

    return (
        <Dialog
            maxWidth="md"
            fullScreen={fullScreen}
            fullWidth={true}
            open={isOpenModalDetailInvoice}
            onClose={() => {
                setKirimEmail(false);
                setEmail("");
                closeModalDetailInvoice();
            }}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            setKirimEmail(false);
                            setEmail("");
                            closeModalDetailInvoice();
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Detail Invoice
                    </Typography>
                </Stack>
            </DialogTitle>
            {detailInvoice && (
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Tanggal
                            </Typography>
                            <Typography variant="subtitle2">
                                {moment(detailInvoice?.tanggalTransaksi).format("DD MMM YYYY HH:mm")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={5}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                No. Invoice
                            </Typography>
                            <Typography variant="subtitle2">
                                {detailInvoice?.nomorInvoice}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Metode
                            </Typography>
                            <Typography variant="subtitle2">
                                {detailInvoice?.metode
                                    ? detailInvoice.metode
                                    : detailInvoice.isWebstore
                                    ? "COD"
                                    : "Tunai"}
                            </Typography>
                        </Grid>
                        {detailInvoice.pelangganWebstore && (
                            <>
                                <Grid item xs={6} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Nama Pelanggan
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {
                                            detailInvoice.pelangganWebstore
                                                .namaPelanggan
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Nomor Pelanggan
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {
                                            detailInvoice.pelangganWebstore
                                                .nomorPelanggan
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Pemilik Rekening
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {detailInvoice.pelangganWebstore
                                            .pemilikRekening ?? "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Rekening Pelanggan
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {detailInvoice.pelangganWebstore
                                            .rekeningPelanggan ?? "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Alamat
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {
                                            detailInvoice.pelangganWebstore
                                                .alamatPelanggan
                                        }
                                        ,{" "}
                                        {toTitleCase(
                                            detailInvoice.pelangganWebstore
                                                .kelurahan,
                                        )}
                                        ,{" "}
                                        {toTitleCase(
                                            detailInvoice.pelangganWebstore
                                                .kecamatan,
                                        )}
                                        ,{" "}
                                        {toTitleCase(
                                            detailInvoice.pelangganWebstore
                                                .kota,
                                        )}
                                        ,{" "}
                                        {toTitleCase(
                                            detailInvoice.pelangganWebstore
                                                .provinsi,
                                        )}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <Box my={2}>
                        <Divider />
                    </Box>

                    <Box display="flex" justifyContent="right" marginTop={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        paramsDetailInvoice.grupProduk === 1
                                    }
                                    onChange={(_event, checked) => {
                                        if (checked) {
                                            setParamsDetailInvoice((prev) => ({
                                                ...prev,
                                                grupProduk: 1,
                                            }));
                                        } else {
                                            setParamsDetailInvoice((prev) => ({
                                                ...prev,
                                                grupProduk: 0,
                                            }));
                                        }
                                    }}
                                />
                            }
                            label="Group by Kategori"
                            sx={{ height: "fit-content" }}
                        />
                    </Box>

                    {paramsDetailInvoice.grupProduk === 1 ? (
                        <Stack spacing={1}>
                            {detailInvoice?.daftarKategori.map(
                                (kategori, kategoriIndex) => (
                                    <Stack key={kategoriIndex}>
                                        <Stack marginBottom={1}>
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                            >
                                                {truncate(
                                                    kategori.kategori,
                                                    20,
                                                )}
                                            </Typography>
                                        </Stack>
                                        <Grid container>
                                            {kategori.items.map(
                                                (item, itemIndex) => (
                                                    <React.Fragment
                                                        key={itemIndex}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Tooltip
                                                                title={
                                                                    item.namaItem
                                                                }
                                                            >
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    fontWeight="500"
                                                                    ml={2}
                                                                >
                                                                    {truncate(
                                                                        item.namaItem,
                                                                        20,
                                                                    )}
                                                                </Typography>
                                                            </Tooltip>
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="#A1A1AA"
                                                                ml={2}
                                                            >
                                                                {toRupiah(
                                                                    item.hargaSatuan,
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                variant="subtitle1"
                                                                fontWeight="500"
                                                            >
                                                                {
                                                                    item.jumlahItem
                                                                }
                                                                x
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            display="flex"
                                                            justifyContent="flex-end"
                                                        >
                                                            <Typography
                                                                variant="subtitle1"
                                                                fontWeight="bold"
                                                                textAlign="end"
                                                            >
                                                                {toRupiah(
                                                                    item.hargaSatuan *
                                                                        item.jumlahItem,
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </React.Fragment>
                                                ),
                                            )}
                                        </Grid>
                                    </Stack>
                                ),
                            )}
                        </Stack>
                    ) : (
                        <Stack spacing={1}>
                            {detailInvoice?.daftarItem.map((items, index) => (
                                <Stack direction="row" key={index}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Tooltip title={items.namaItem}>
                                                <Typography
                                                    variant="subtitle1"
                                                    fontWeight="bold"
                                                >
                                                    {truncate(
                                                        items.namaItem,
                                                        20,
                                                    )}
                                                </Typography>
                                            </Tooltip>
                                            <Typography
                                                variant="subtitle2"
                                                color="#A1A1AA"
                                            >
                                                {toRupiah(items.hargaSatuan)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                            >
                                                {items.jumlahItem}x
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                                textAlign="end"
                                            >
                                                {toRupiah(
                                                    items.hargaSatuan *
                                                        items.jumlahItem,
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            ))}
                        </Stack>
                    )}

                    <Box my={2}>
                        <Divider />
                    </Box>
                    <Stack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" color="#A1A1AA">
                                    Subtotal
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    textAlign="end"
                                >
                                    {toRupiah(detailInvoice?.totalHarga)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" color="#A1A1AA">
                                    Diskon
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    textAlign="end"
                                >
                                    {toRupiah(detailInvoice?.diskon)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" color="#A1A1AA">
                                    {detailInvoice?.pajakPersen
                                        ? `Pajak (${detailInvoice?.pajakPersen}%)`
                                        : `Pajak`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    textAlign="end"
                                >
                                    {detailInvoice?.pajak
                                        ? toRupiah(detailInvoice?.pajak)
                                        : "Rp. 0"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack
                        my={2}
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    textAlign="end"
                                >
                                    {toRupiah(detailInvoice.totalPembayaran)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" color="#A1A1AA">
                                    Uang yang Dibayar
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    textAlign="end"
                                >
                                    {toRupiah(detailInvoice.uangYangDibayar)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" color="#A1A1AA">
                                    Kembalian
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    textAlign="end"
                                >
                                    {toRupiah(detailInvoice.kembalian)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                    {detailInvoice.charge && detailInvoice.tipeCharge && (
                        <Stack
                            direction="row"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <Grid item xs={3}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Charge {detailInvoice.metode}{" "}
                                        {detailInvoice.tipeCharge === "persen"
                                            ? `(${detailInvoice.charge}%)`
                                            : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        textAlign="end"
                                    >
                                        {detailInvoice.tipeCharge === "persen"
                                            ? toRupiah(
                                                  (detailInvoice.uangYangDibayar *
                                                      Number(
                                                          detailInvoice.charge,
                                                      )) /
                                                      100,
                                              )
                                            : toRupiah(detailInvoice.charge)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    )}
                    <Stack
                        mb={5}
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            alignItems="center"
                            // justifyContent="flex-end"
                        >
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <Typography
                                    variant="subtitle2"
                                    color="#A1A1AA"
                                    mb={1}
                                >
                                    Keterangan
                                </Typography>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={9}>
                                <TextField
                                    disabled
                                    multiline
                                    rows={2}
                                    fullWidth
                                    value={detailInvoice.keterangan}
                                    sx={{
                                        "& .MuiOutlinedInput-input.Mui-disabled":
                                            {
                                                color: "rgba(0, 0, 0, 0.87)",
                                                WebkitTextFillColor:
                                                    "rgba(0, 0, 0, 0.87)",
                                            },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography>Pilih Tipe Struk</Typography>
                        <ToggleButtonGroup
                            value={tipeStruk}
                            exclusive
                            size="small"
                            onChange={handleTipeStruk}
                        >
                            <ToggleButton
                                value={TipeStruk.Mobile}
                                sx={{
                                    display: "flex",
                                    paddingX: 2,
                                    textTransform: "none",
                                }}
                            >
                                <b>A8 58mm</b>
                            </ToggleButton>
                            <ToggleButton
                                value={TipeStruk.Mobile80}
                                sx={{
                                    display: "flex",
                                    paddingX: 2,
                                    textTransform: "none",
                                }}
                            >
                                <b>A8 80mm</b>
                            </ToggleButton>
                            <ToggleButton
                                value={TipeStruk.PC}
                                sx={{
                                    display: "flex",
                                    paddingX: 2,
                                    textTransform: "none",
                                }}
                            >
                                <b>A4</b>
                            </ToggleButton>
                            <ToggleButton
                                value={TipeStruk.MobileDapur}
                                sx={{
                                    display: "flex",
                                    paddingX: 2,
                                    textTransform: "none",
                                }}
                            >
                                <b>A8 58mm Dapur</b>
                            </ToggleButton>
                            <ToggleButton
                                value={TipeStruk.Mobile80Dapur}
                                sx={{
                                    display: "flex",
                                    paddingX: 2,
                                    textTransform: "none",
                                }}
                            >
                                <b>A8 80mm Dapur</b>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    <Box
                        display="flex"
                        // justifyContent="flex-start"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={kirimEmail}
                                    onChange={(_, checked) => {
                                        handleKirimEmail(checked);
                                    }}
                                />
                            }
                            label="Kirim struk"
                            sx={{
                                height: "fit-content",
                            }}
                        />
                    </Box>
                    {kirimEmail && (
                        <Grid
                            container
                            alignItems="center"
                            spacing={1}
                            marginBottom={2}
                        >
                            <Grid item xs={12} md={6}>
                                <TextField
                                    value={email}
                                    size="small"
                                    type="email"
                                    fullWidth
                                    placeholder="8316352725"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                +62
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => setEmail(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    disabled={email.length <= 0}
                                    variant="outlined"
                                    onClick={() => {
                                        setKirimEmail(false);
                                        setEmail("");
                                        closeModalDetailInvoice();
                                    }}
                                    href={`https://wa.me/62${email}?text=Struk%20pembayaran%20ULO%20${detailInvoice.nomorInvoice}%20${window.location.origin}/struk/${detailInvoice.encrypt}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    fullWidth
                                >
                                    Kirim struk ke WhatsApp
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    <Stack
                        width="100%"
                        direction="column"
                        alignItems="center"
                        spacing={1}
                    >
                        <Button
                            variant="text"
                            onClick={() => {
                                setKirimEmail(false);
                                setEmail("");
                                closeModalDetailInvoice();
                            }}
                            fullWidth
                            sx={{ display: { xs: "block", md: "none" } }}
                        >
                            Kembali
                        </Button>
                        <Box width="100%">
                            {ukmData &&
                                (tipeStruk === TipeStruk.Mobile ||
                                tipeStruk ===
                                    TipeStruk.Mobile80 ||
                                tipeStruk ===
                                    TipeStruk.MobileDapur ||
                                tipeStruk ===
                                    TipeStruk.Mobile80Dapur ? (
                                    <BlobProvider
                                        document={
                                            <PrintStrukPdf
                                                is80mm={
                                                    tipeStruk ===
                                                    TipeStruk.Mobile80 || tipeStruk ===
                                                    TipeStruk.Mobile80Dapur
                                                }
                                                isIncludeDapur={isIncludeDapur}
                                                namaToko={ukmData.namaToko}
                                                alamatToko={ukmData.alamatToko}
                                                telpToko={ukmData.noTelp}
                                                gambarToko={
                                                    ukmData?.gambarTokoBW ===
                                                    null
                                                        ? undefined
                                                        : ukmData?.gambarTokoBW
                                                }
                                                tanggalTransaksi={
                                                    detailInvoice.tanggalTransaksi
                                                }
                                                transaksiKe={
                                                    detailInvoice.transaksiKe
                                                }
                                                kasir={detailInvoice.kasir}
                                                sales={detailInvoice.sales}
                                                total={
                                                    detailInvoice.totalPembayaran
                                                }
                                                cart={newCart}
                                                groupByKategori={
                                                    groupByKategori
                                                }
                                                category={
                                                    detailInvoice.daftarKategori
                                                }
                                                diskon={detailInvoice.diskon}
                                                pajak={detailInvoice.pajak}
                                                pajakPersen={
                                                    detailInvoice.pajakPersen
                                                }
                                                tunai={
                                                    detailInvoice.uangYangDibayar
                                                }
                                                kembalian={
                                                    detailInvoice.kembalian
                                                }
                                                isPro={ukmData.isPro}
                                                namaMetode={
                                                    detailInvoice.metode ??
                                                    "Tunai"
                                                }
                                                html={
                                                    findCustomStruk &&
                                                    findCustomStruk.html !==
                                                        null
                                                        ? findCustomStruk?.html
                                                        : undefined
                                                }
                                                keterangan={
                                                    detailInvoice.keterangan
                                                }
                                                noMetode={
                                                    detailInvoice.metodeArr
                                                        .noTujuan
                                                }
                                            />
                                        }
                                    >
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="center"
                                                    fullWidth
                                                    variant="contained"
                                                    href={url ?? ""}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Print Ulang Struk
                                                </LoadingButton>
                                            );
                                        }}
                                    </BlobProvider>
                                ) : (
                                    <BlobProvider
                                        document={
                                            <PrintStrukA4
                                                ukmData={ukmData}
                                                tanggalTransaksi={
                                                    detailInvoice.tanggalTransaksi
                                                }
                                                transaksiKe={
                                                    detailInvoice.transaksiKe
                                                }
                                                kasir={detailInvoice.kasir}
                                                sales={detailInvoice.sales}
                                                total={
                                                    detailInvoice.totalPembayaran
                                                }
                                                cart={newCart}
                                                category={
                                                    detailInvoice.daftarKategori
                                                }
                                                groupByKategori={
                                                    groupByKategori
                                                }
                                                diskon={detailInvoice.diskon}
                                                pajak={detailInvoice.pajak}
                                                pajakPersen={
                                                    detailInvoice.pajakPersen
                                                }
                                                tunai={
                                                    detailInvoice.uangYangDibayar
                                                }
                                                kembalian={
                                                    detailInvoice.kembalian
                                                }
                                                namaMetode={
                                                    detailInvoice.metode ??
                                                    "Tunai"
                                                }
                                                html={
                                                    findCustomStruk &&
                                                    findCustomStruk.html !==
                                                        null
                                                        ? findCustomStruk?.html
                                                        : undefined
                                                }
                                                keterangan={
                                                    detailInvoice.keterangan
                                                }
                                                namaPemegang={
                                                    detailInvoice.metodeArr
                                                        .namaPemegang
                                                }
                                                nomorTujuan={
                                                    detailInvoice.metodeArr
                                                        .noTujuan
                                                }
                                                qr={
                                                    detailInvoice.metodeArr
                                                        .gambar
                                                }
                                            />
                                        }
                                    >
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="center"
                                                    fullWidth
                                                    variant="contained"
                                                    href={url ?? ""}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Print Ulang Struk
                                                </LoadingButton>
                                            );
                                        }}
                                    </BlobProvider>
                                ))}
                        </Box>
                    </Stack>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default ModalDetailInvoice;
