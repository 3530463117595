import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "../../services/axios";
import qs from "query-string";
import moment from "moment";
import momentTZ from "moment-timezone";
import { BlobProvider } from "@react-pdf/renderer";
import PrintStrukKesimpulan from "../../components/PrintStrukKesimpulan/PrintStrukKesimpulan";
import useUser from "../../services/queries/useUser";
import PrintIcon from "@mui/icons-material/Print";


interface IModalPrintTutupBukuProps {
    open: boolean;
    onClose: () => void;
    namaUkm?: string;
    gambarToko?: string;
    alamatToko: string;
    telpToko: string;
    tanggalTransaksi: string;
    kesimpulan: any;
}

const ModalPrintTutupBuku = ({
    open,
    onClose,
    namaUkm,
    gambarToko,
    alamatToko,
    telpToko,
    tanggalTransaksi,
    kesimpulan,
}: IModalPrintTutupBukuProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    
    const { data: user, status: loadingUser } = useUser();

    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [state, setState] = useState({
        isBarangTerjual: false,
    });

    const timezone = momentTZ.tz.guess();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    }; 

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={isPhoneScreen}
            fullWidth
            open={open}
            onClose={onClose}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Print Tutup Buku
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <FormControl
                    sx={{ px: 1 }}
                    component="fieldset"
                    variant="standard"
                >
                    <FormLabel component="legend">
                        Pilih apakah ingin menyertakan "Barang Terjual" dalam print:
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.isBarangTerjual}
                                    onChange={handleChange}
                                    name="isBarangTerjual"
                                />
                            }
                            label="Sertakan Barang Terjual"
                        /> 
                    </FormGroup>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <BlobProvider
                    document={
                        <PrintStrukKesimpulan
                            namaToko={namaUkm ?? "Toko"}
                            alamatToko={
                                alamatToko ?? "Toko"
                            }
                            telpToko={telpToko ?? "Toko"}
                            gambarToko={
                                gambarToko === null
                                    ? undefined
                                    : gambarToko
                            }
                            // tanggalTransaksi={nowK}
                            tanggalTransaksi={moment(
                                tanggalTransaksi,
                            ).format("DD MMM YYYY")}
                            html={undefined}
                            kesimpulan={kesimpulan ?? []}
                            isBarangTerjual={state.isBarangTerjual}
                        />
                    }
                >
                    {({ blob, url, loading, error }) => {
                        return (
                            <LoadingButton
                                disabled={
                                    user &&
                                    !user.akses.laporan.includes(36)
                                }
                                loading={loading}
                                startIcon={
                                    isPhoneScreen ||
                                    isTabletScreen ? undefined : (
                                        <PrintIcon />
                                    )
                                }
                                loadingPosition="center"
                                size="small"
                                variant="contained"
                                href={url ?? ""}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {/* Print Tutup Buku */}
                                {isPhoneScreen || isTabletScreen ? (
                                    <PrintIcon />
                                ) : (
                                    "Print Tutup Buku"
                                )}
                            </LoadingButton>
                        );
                    }}
                </BlobProvider>
            </DialogActions>
        </Dialog>
    );
};

export default ModalPrintTutupBuku;
