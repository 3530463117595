import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useState } from "react";
import toRupiah from "../../utils/toRupiah";
import ModalDetailInvoice from "../ModalDetailInvoice/ModalDetailInvoice";
import Button from "@mui/material/Button";
import moment from "moment";
import "moment/locale/id";

interface ICardInvoiceProps {
    detailInvoiceId: number;
    noInvoice: string;
    tanggalInvoice: string;
    hargaPenjualan: number;
    isReverted: number;
    metode: string;
}

const CardInvoice = ({
    detailInvoiceId,
    noInvoice,
    tanggalInvoice,
    hargaPenjualan,
    isReverted,
    metode,
}: ICardInvoiceProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    // const [isOpenModalRevertInvoice, setIsOpenModalRevertInvoice] =
    //     useState(false);
    const [isOpenModalDetailInvoice, setIsOpenModalDetailInvoice] =
        useState(false);
    const [selectedId, setSelectedId] = useState<number>(0);

    const openModalDetailInvoice = (id: number) => {
        setSelectedId(id);
        setIsOpenModalDetailInvoice(true);
    };

    const closeModalDetailInvoice = () => {
        setIsOpenModalDetailInvoice(false);
    };

    return (
        <React.Fragment>
            <Card
                variant="outlined"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 1,
                    padding: isTabletScreen ? 1 : 2,
                }}
            >
                <Box>
                    <Typography fontWeight="bold">{noInvoice}</Typography>
                    <Typography fontSize={12} color="#76747C">
                        {moment(tanggalInvoice).format("DD MMM YYYY HH:mm")}
                    </Typography>
                    <Typography
                        marginTop={1}
                        fontSize={12}
                        fontWeight="bold"
                        color="#76747C"
                    >
                        {isReverted
                            ? "Pembayaran dibatalkan"
                            : hargaPenjualan < 0
                            ? "Laporan pembatalan"
                            : `${metode}: ${toRupiah(hargaPenjualan)}`}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            openModalDetailInvoice(detailInvoiceId);
                        }}
                        size="small"
                        color="buttongreen"
                        disableElevation
                        sx={{
                            minWidth: "unset",
                            padding: "8px",
                            width: "32px",
                            height: "32px",
                        }}
                    >
                        <VisibilityIcon
                            sx={{ width: "16px", height: "16px" }}
                        />
                    </Button>
                </Stack>
            </Card>
            <ModalDetailInvoice
                id={selectedId}
                fullScreen={fullScreen}
                isOpenModalDetailInvoice={isOpenModalDetailInvoice}
                closeModalDetailInvoice={closeModalDetailInvoice}
            />
        </React.Fragment>
    );
};

export default CardInvoice;
