import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "../../services/axios";
import qs from "query-string";
import moment from "moment";
import momentTZ from "moment-timezone";


interface IModalUnduhLaporanProps {
    open: boolean;
    onClose: () => void;
    paramsUnduhLaporan: {
        ukmId?: number;
        tanggalAwal: string;
        tanggalAkhir: string;
    };
    namaUkm?: string;
}

const ModalUnduhLaporan = ({
    open,
    onClose,
    paramsUnduhLaporan,
    namaUkm,
}: IModalUnduhLaporanProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [state, setState] = useState({
        isInvoice: true,
        isProduk: false,
        isMutasi: false,
    });

    const timezone = momentTZ.tz.guess();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const downloadLaporan = async () => {
        setIsButtonLoading(true);
        try {
            const params = qs.stringifyUrl({
                url: `/api/downloadlaporan`,
                query: {
                    ukmId: paramsUnduhLaporan.ukmId,
                    tanggalAwal: paramsUnduhLaporan.tanggalAwal,
                    tanggalAkhir:
                        paramsUnduhLaporan.tanggalAkhir === ""
                            ? paramsUnduhLaporan.tanggalAwal
                            : paramsUnduhLaporan.tanggalAkhir,
                    isInvoice: state.isInvoice ? 1 : undefined,
                    isProduk: state.isProduk ? 1 : undefined,
                    isMutasi: state.isMutasi ? 1 : undefined,
                    timezone: timezone,
                },
            });

            const { data } = await axios.get(params, {
                responseType: "blob",
            });

            const file = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            });

            const fileURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute(
                "download",
                `Laporan_${namaUkm || "UKM"}${
                    state.isInvoice ? "_Invoice" : ""
                }${state.isMutasi ? "_Mutasi" : ""}${
                    state.isProduk ? "_Stok Terjual" : ""
                }`,
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setIsButtonLoading(false);
        } catch (error) {
            console.error("Error downloading the file", error);
            setIsButtonLoading(false);
        } finally {
            setIsButtonLoading(false);
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={isPhoneScreen}
            fullWidth
            open={open}
            onClose={onClose}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Unduh Laporan
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <FormControl
                    sx={{ px: 1 }}
                    component="fieldset"
                    variant="standard"
                >
                    <FormLabel component="legend">
                        Pilih laporan yang ingin diunduh:
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.isInvoice}
                                    onChange={handleChange}
                                    name="isInvoice"
                                />
                            }
                            label="Invoice"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.isMutasi}
                                    onChange={handleChange}
                                    name="isMutasi"
                                />
                            }
                            label="Mutasi Stok"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.isProduk}
                                    onChange={handleChange}
                                    name="isProduk"
                                />
                            }
                            label="Stok Terjual"
                        />
                    </FormGroup>
                </FormControl>
                <Typography mt={1}>
                    Tanggal{" "}
                    {moment(paramsUnduhLaporan.tanggalAwal).format("LL")} -{" "}
                    {paramsUnduhLaporan.tanggalAkhir === ""
                        ? moment(paramsUnduhLaporan.tanggalAwal).format("LL")
                        : moment(paramsUnduhLaporan.tanggalAkhir).format("LL")}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="contained"
                    onClick={() => {
                        downloadLaporan();
                    }}
                >
                    Unduh Laporan
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ModalUnduhLaporan;
